import React, { useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"
import { useLocation } from "@reach/router"
import striptags from "striptags"
import moment from "moment"

import Post from "components/post"
import Layout from "components/layout"
import SEO from "components/seo"
import { getPost, insightsPath } from "utils/insights"
import { daArticlePath } from "utils/demand-academy"
import { LoadingScreen, getPremiumPost } from "pages/insights/premium"

// Rules for loading posts dynamically
// If context === 404 (loaded by catch all route)
//   => if id (CMS preview) => load regular with apiKey
//   => if pathname (not yet published post) => load regular
// If context !== 404 (article exists)
//   => if more than 6 months since publication => mark post as non premium
//      (this loads the whole article during build without restrictions)
//   => if key query param (premium token) => load premium with token
//   => if no key query param (premium token) => load regular

export default ({ data, pageContext }) => {
  const { insightsArticle: cmsPost } = data.gcms
  const expireAt = moment().subtract(6, "months")
  if (
    cmsPost &&
    cmsPost?.publishingDate < expireAt.toISOString() &&
    cmsPost?.body?.text !== null &&
    cmsPost?.body?.text.trim() !== ""
  ) {
    cmsPost.premium = false
  }

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [post, setPost] = useState(cmsPost)
  // Post will be ready to use from the beginning if it's not
  // unknown (loaded by the catch all route) and not premium.
  const [ready, setReady] = useState(
    pageContext?.id !== "404" && !cmsPost.premium
  )
  const [premiumLoading, setPremiumLoading] = useState(false)
  const [shared, setShared] = useState(false)
  const [premium, setPremium] = useState(false)
  const [premiumPost, setPremiumPost] = useState(null)

  async function loadPremiumPost(token) {
    setPremium(true)
    setPremiumLoading(true)
    setReady(true)

    let loadedPost

    try {
      loadedPost = await getPremiumPost(token)
    } catch (error) {
      console.log("Error loading post", error)
    }

    if (loadedPost) {
      setShared(true)
      loadedPost.relativePath = insightsPath(loadedPost)
      setPremiumPost(loadedPost)
      window.history.replaceState({}, loadedPost.title, loadedPost.relativePath)
    }

    setPremiumLoading(false)
  }

  async function loadPost(path, id, apiKey) {
    const dPost = await getPost(path, id, apiKey)
    if (dPost) {
      if (dPost.premium && dPost?.body?.text?.trim()) {
        dPost.premium = false
      }
      setPost(dPost)
      setReady(true)
    } else {
      navigate("/404")
    }
  }

  useEffect(() => {
    async function load() {
      const pathname = location.pathname || ""
      const id = queryParams.get("id") || ""
      const apiKey = queryParams.get("api-key") || ""
      const premiumToken = queryParams.get("key")

      if (premiumToken) {
        // Attempt to load premium content
        await loadPremiumPost(premiumToken)
      } else if (pathname || id) {
        // Attempt to load post by pathname or by id
        await loadPost(encodeURIComponent(pathname), id, apiKey)
      }
    }

    if (!ready) {
      load()
    }
  }, [])

  if (post) {
    post.relativePath = insightsPath(post)
    if (post.youtubeId && !post.externalUrl) {
      post.externalUrl = "https://www.youtube.com/watch?v=" + post.youtubeId
    }
  }
  const imageURL = post?.featuredImage?.url
  const category = post?.insightsCategories?.length
    ? post.insightsCategories[0]
    : false
  const path = post
    ? post.demandAcademyTier
      ? daArticlePath(post)
      : insightsPath(post)
    : null
  const meta = [
    {
      property: "og:type",
      content: "article",
    },
    {
      property: "article:section",
      content: category?.name,
    },
  ]
  const sharePreviewUrls = {
    social: imageURL,
    twitter: imageURL,
  }

  return (
    <Layout>
      <SEO
        title={post?.title}
        description={
          post?.summary?.text || striptags(post?.legacySummary) || ""
        }
        meta={meta}
        path={path}
        sharePreviewUrls={sharePreviewUrls}
      />
      {!!ready && (
        <>
          {!!premium && !premiumPost && (
            <LoadingScreen loading={premiumLoading} />
          )}
          {!!premium && !!premiumPost && (
            <Post post={premiumPost} shared={shared} />
          )}
          {!premium && !!post && <Post post={post} shared={shared} />}
        </>
      )}
      {!ready && (
        <div className="h-screen w-full flex flex-col justify-center items-center text-white">
          Loading...
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  query PostById($id: ID!) {
    gcms {
      insightsArticle(where: { id: $id }) {
        id
        title
        slug
        body {
          html
          text
        }
        legacyBody
        legacySummary
        summary {
          html
          text
        }
        insightsCategories {
          name
          slug
        }
        publishingDate
        featuredImage {
          url
          altText
          handle
        }
        premium
        wordpressId
        metadata
        articlePublisher {
          publisher
        }
        externalUrl
        youtubeId
        format
        markets {
          name
          isoCode
        }
        titlesJson
        moviesJson
        talentJson
        platforms {
          name
          slug
        }
        metrics {
          name
        }
        genres {
          name
          slug
        }
        industries {
          title
          slug
        }
        applications {
          title
          slug
        }
        demandAcademyTier {
          name
        }
        demandAcademySlug
      }
    }
  }
`
